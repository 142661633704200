class ZIDXAccountRemapListingIndex implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountRemapListingIndexContainer";
    }
    getMatchURL(){
        return "/account/data-feeds/listing-index/remap-data";
    }
    render(){
        // console.log("listing index remap select");
    }
}